/*
 *  Plyr
 */

.plyr--full-ui input[type='range'] {
  color: #d0df00 !important;
}

.plyr--full-ui {
  border-radius: 16px !important;
}

.plyr__control--overlaid {
  background: #d0df00 !important;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded='true'] {
  background: #d0df00 !important;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(#d0df00, 0.5) !important;
}

.plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']::before {
  background: #d0df00 !important;
}

.plyr__captions .plyr__caption {
  background: unset !important;
  text-shadow:
        /* first layer at 1px */ -1px -1px 0px #000, 0px -1px 0px #000,
    1px -1px 0px #000, -1px 0px 0px #000, 1px 0px 0px #000, -1px 1px 0px #000, 0px 1px 0px #000,
    1px 1px 0px #000, /* second layer at 2px */ -2px -2px 0px #000, -1px -2px 0px #000,
    0px -2px 0px #000, 1px -2px 0px #000, 2px -2px 0px #000, 2px -1px 0px #000, 2px 0px 0px #000,
    2px 1px 0px #000, 2px 2px 0px #000, 1px 2px 0px #000, 0px 2px 0px #000, -1px 2px 0px #000,
    -2px 2px 0px #000, -2px 1px 0px #000, -2px 0px 0px #000, -2px -1px 0px #000;
}

@media (max-width: 768px) {
  .plyr--full-ui {
    border-radius: 0px !important;
  }
}

@media (min-width: 1024px) {
  .plyr__captions .plyr__caption {
    font-size: 21px !important;
  }
}

@media (min-width: 1100px) {
  .plyr__captions .plyr__caption {
    font-size: 23px !important;
  }
}

@media (min-width: 1200px) {
  .plyr__captions .plyr__caption {
    font-size: 25px !important;
  }
}

@media (min-width: 1300px) {
  .plyr__captions .plyr__caption {
    font-size: 27px !important;
  }
}

@media (min-width: 1500px) {
  .plyr__captions .plyr__caption {
    font-size: 30px !important;
  }
}

@media (min-width: 1600px) {
  .plyr__captions .plyr__caption {
    font-size: 30px !important;
  }
}

/*
 *  nprogress
 */

#nprogress {
  position: relative;
  z-index: 9999999;
}
